import React from "react";
import ReactDOM from "react-dom/client";
import { useParams } from "react-router-dom";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./index.css";
import App from "./App";

import About from "./About";

import Login from "./login";
import Register from "./register";
import Leads from "./leads";
import Users from "./users";
import Exports from "./exports";
import Subscription from "./subscription";
import Checkout from "./checkout";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        {/* <Route path="/About" element={<About />} /> */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/leads" element={<Leads />} />
        <Route path="/users" element={<Users />} />
        <Route path="/exports" element={<Exports />} />
        <Route path="/subscription" element={<Subscription />} />
        <Route path="/checkout" element={<Checkout />} />
       
      </Routes>
    </Router>
  </React.StrictMode>
);
