// ImportForm.js
import { Textarea } from '@material-tailwind/react';
import React, { useState } from 'react';
import Select from 'react-select';
import apiClient from './apiClient'; // Import the custom axios instance
import { useEffect } from "react";


const LeadForm = ({ onSubmit }) => {
  const [file, setFile] = useState(null);
  const [Loading, setLoading] = useState(null);
  
  const Employee = [
    {
        "id": 1,
        "name": "1-10"
      },
      {
        "id": 2,
        "name": "11-50"
      },
      {
        "id": 3,
        "name": "51-200"
      },
      {
        "id": 4,
        "name": "201-500"
      },
      {
        "id": 5,
        "name": "501-1000"
      },
      {
        "id": 6,
        "name": "1001-5000"
      },
      {
        "id": 7,
        "name": "5001-10000"
      },
      {
        "id": 8,
        "name": "10001+"
      },
      {
        "id": 10,
        "name": "True"
      }
  ];
  const Revenue = [
    {
        "id": 1,
        "name": "0M - 1M"
      },
      {
        "id": 2,
        "name": "1M - 10M"
      },
      {
        "id": 3,
        "name": "10M - 100M"
      },
      {
        "id": 4,
        "name": "100M - 500M"
      },
      {
        "id": 5,
        "name": "500M - 1B"
      },
      {
        "id": 6,
        "name": "1B+"
      },
      {
        "id": 8,
        "name": "True"
      }
  ];

  const optionsemp = Employee.map(employee => ({
    value: employee.id,
    label: employee.name,
  }));

  const optionsrev = Revenue.map(revenue => ({
    value: revenue.id,
    label: revenue.name,
  }));

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: '5px',
      borderColor: '#ccc',
     
      zIndex: 1, // Base z-index for the control
      '&:hover': {
        borderColor: '#007bff'
      }
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '8px',
     
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
      zIndex: 1500, // High z-index to ensure dropdown menu appears above other content
    }),
    option: (provided, state) => ({
      ...provided,
      padding: '10px',
      backgroundColor: state.isSelected ? '#007bff' : '#fff',
      color: state.isSelected ? '#fff' : '#333',
      '&:hover': {
        backgroundColor: '#007bff',
        color: '#fff'
      }
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 1500, // Ensure the menu portal has a high z-index for proper layering
    }),
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(file);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        
        <div className='row p-2'>
             <div className='col-sm-3 mb-2 font-change1'>
                <label className='mb-2'><b>First Name</b></label>
                <div className="form-input">
                    <input className='form-control font-change' type="text" placeholder='Enter First Name' id="fileInput" />
                </div>
            </div>
             <div className='col-sm-3 mb-2 font-change1'>
                <label className='mb-2'><b>Last Name</b></label>
                <div className="form-input">
                    <input className='form-control font-change' type="text" placeholder='Enter Last Name' id="fileInput" />
                </div>
            </div>
             <div className='col-sm-3 mb-2 font-change1'>
                <label className='mb-2'><b>Title</b></label>
                <div className="form-input">
                    <input className='form-control font-change' type="text" placeholder='Enter Title' id="fileInput" />
                </div>
            </div>
             <div className='col-sm-3 mb-2 font-change1'>
                <label className='mb-2'><b>Seniority</b></label>
                <div className="form-input">
                    <input className='form-control font-change' type="text" placeholder='Enter Seniority' id="fileInput" />
                </div>
            </div>
        </div>
        <div className='row p-2'>
             <div className='col-sm-3 mb-2 font-change1'>
                <label className='mb-2'><b>Email</b></label>
                <div className="form-input">
                    <input className='form-control font-change' type="text" placeholder='Enter Email' id="fileInput" />
                </div>
            </div>
             <div className='col-sm-3 mb-2 font-change1'>
                <label className='mb-2'><b>Work Phone</b></label>
                <div className="form-input">
                    <input className='form-control font-change' type="text" placeholder='Enter Work Phone' id="fileInput" />
                </div>
            </div>
             <div className='col-sm-3 mb-2 font-change1'>
                <label className='mb-2'><b>Mobile Phone</b></label>
                <div className="form-input">
                    <input className='form-control font-change' type="text" placeholder='Enter Mobile Phone' id="fileInput" />
                </div>
            </div>
             <div className='col-sm-3 mb-2 font-change1'>
                <label className='mb-2'><b>Country</b></label>
                <div className="form-input">
                    <input className='form-control font-change' type="text" placeholder='Enter Country' id="fileInput" />
                </div>
            </div>
        </div>
        <div className='row p-2'>
             <div className='col-sm-3 mb-2 font-change1'>
                <label className='mb-2'><b>State</b></label>
                <div className="form-input">
                    <input className='form-control font-change' type="text" placeholder='Enter State' id="fileInput" />
                </div>
            </div>
             <div className='col-sm-3 mb-2 font-change1'>
                <label className='mb-2'><b>City</b></label>
                <div className="form-input">
                    <input className='form-control font-change' type="text" placeholder='Enter City' id="fileInput" />
                </div>
            </div>
             <div className='col-sm-3 mb-2 font-change1'>
                <label className='mb-2'><b>Person Linkedin URL</b></label>
                <div className="form-input">
                    <input className='form-control font-change' type="text" placeholder='Enter Person Linkedin URL' id="fileInput" />
                </div>
            </div>
             <div className='col-sm-3 mb-2 font-change1'>
                <label className='mb-2'><b>Comapny</b></label>
                <div className="form-input">
                    <input className='form-control font-change' type="text" placeholder='Enter Comapny Name' id="fileInput" />
                </div>
            </div>
        </div>
        <div className='row p-2'>
             <div className='col-sm-3 mb-2 font-change1'>
                <label className='mb-2'><b>Website</b></label>
                <div className="form-input">
                    <input className='form-control font-change' type="text" placeholder='Enter Website' id="fileInput" />
                </div>
            </div>
             <div className='col-sm-3 mb-2 font-change1'>
                <label className='mb-2'><b>Industry</b></label>
                <div className="form-input">
                    <input className='form-control font-change' type="text" placeholder='Enter Industry' id="fileInput" />
                </div>
            </div>
             <div className='col-sm-3 mb-2 font-change1'>
                <label className='mb-2'><b>Company Country</b></label>
                <div className="form-input">
                    <input className='form-control font-change' type="text" placeholder='Enter Company Country' id="fileInput" />
                </div>
            </div>
             <div className='col-sm-3 mb-2 font-change1'>
                <label className='mb-2'><b>Company State</b></label>
                <div className="form-input">
                    <input className='form-control font-change' type="text" placeholder='Enter Company State' id="fileInput" />
                </div>
            </div>
        </div>
        <div className='row p-2'>
             <div className='col-sm-3 mb-2 font-change1'>
                <label className='mb-2'><b>Company City</b></label>
                <div className="form-input">
                    <input className='form-control font-change' type="text" placeholder='Enter Company City' id="fileInput" />
                </div>
            </div>
             <div className='col-sm-3 mb-2 font-change1'>
                <label className='mb-2'><b>Employees</b></label>
                <div className="form-input">
                    <input className='form-control font-change' type="text" placeholder='Enter Employees' id="fileInput" />
                </div>
            </div>
             <div className='col-sm-3 mb-2 font-change1'>
                <label className='mb-2'><b>Employee Range</b></label>
                <div className="form-input">
                    <Select
                        styles={customStyles}
                        options={optionsemp}
                        name='emprange'
                        placeholder='Select Range'
                        isSearchable={false}
                        />
                </div>
            </div>
             <div className='col-sm-3 mb-2 font-change1'>
                <label className='mb-2'><b>Annual Revenue</b></label>
                <div className="form-input">
                    <input className='form-control font-change' type="text" placeholder='Enter Annual Revenue' id="fileInput" />
                </div>
            </div>
        </div>
        <div className='row p-2'>
             <div className='col-sm-3 mb-2 font-change1'>
                <label className='mb-2'><b>Revenue Range</b></label>
                <div className="form-input">
                <Select
                        styles={customStyles}
                        options={optionsrev}
                        name='revrange'
                        placeholder='select Range'
                        isSearchable={false}
                        />
                </div>
            </div>
             <div className='col-sm-3 mb-2 font-change1'>
                <label className='mb-2'><b>Company Linkedin URL</b></label>
                <div className="form-input">
                    <input className='form-control font-change' type="text" placeholder='Enter Company Linkedin URL' id="fileInput" />
                </div>
            </div>
             <div className='col-sm-3 mb-2 font-change1'>
                <label className='mb-2'><b>Facebook URL</b></label>
                <div className="form-input">
                    <input className='form-control font-change' type="text" placeholder='Enter Facebook URL' id="fileInput" />
                </div>
            </div>
             <div className='col-sm-3 mb-2 font-change1'>
                <label className='mb-2'><b>Twitter URL</b></label>
                <div className="form-input">
                    <input className='form-control font-change' type="text" placeholder='Enter Twitter URL' id="fileInput" />
                </div>
            </div>
        </div>
        <div className='row p-2'>
             <div className='col-sm-3 mb-2 font-change1'>
                <label className='mb-2'><b>Departments</b></label>
                <div className="form-input">
                    <Textarea className='form-control font-change' type="text" placeholder='Enter Departments' id="fileInput"></Textarea>
                </div>
            </div>
             <div className='col-sm-3 mb-2 font-change1'>
                <label className='mb-2'><b>Keywords</b></label>
                <div className="form-input">
                    <Textarea className='form-control font-change' type="text" placeholder='Enter Keywords' id="fileInput"></Textarea>
                </div>
            </div>
             <div className='col-sm-3 mb-2 font-change1'>
                <label className='mb-2'><b>Technologies</b></label>
                <div className="form-input">
                    <Textarea className='form-control font-change' type="text" placeholder='Enter Technologies' id="fileInput"></Textarea>
                </div>
            </div>
             <div className='col-sm-3 mb-2 font-change1'>
                <label className='mb-2'><b>Product and Services</b></label>
                <div className="form-input">
                    <Textarea className='form-control font-change' type="text" placeholder='Enter Product and Services' id="fileInput"></Textarea>
                </div>
            </div>
        </div>
      </div>
      <div className="form-buttons">
        <div className='import-btn'>Create</div>
        
      </div>
    </form>
  );
};

export default LeadForm;
