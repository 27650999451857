// ImportForm.js
import React, { useState } from 'react';
import LeadForm from './LeadForm';
import Modal from './modal';

const ImportForm = ({ onSubmit }) => {
  const [file, setFile] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(file);
  };

  return (
    <>
        <Modal show={showModal} onClose={handleCloseModal} title="Add Leads">
            <LeadForm  />
        </Modal>
    <form onSubmit={handleSubmit}>
         
      <div className="form-group">
        <label><b>Select Leads file</b></label>
        <div className="file-input-wrapper">
            <input type="file" id="fileInput" className="file-input"  />
            <label htmlFor="fileInput" className="file-input-label">
                <span className="file-input-text">Import Leads</span>
                <span className="file-input-button">Browser</span>
            </label>
        </div>
      </div>
      <div className="form-buttons">
        <div className='import-btn'>Import Leads</div>
        <div className='addleads-btn'  onClick={handleOpenModal}>Add Leads</div>
      </div>
    </form>
    </>
  );
};

export default ImportForm;
