
import feather from "feather-icons";
import Sidebar from "./Sidebar";

import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import Login from "./login";
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import profile from './assets/profile.JPG'


import { Doughnut } from "react-chartjs-2";

import { Link } from "react-router-dom";

import apiClient from './apiClient'; // Import the custom axios instance

import "./App.css";


import { useEffect } from "react";




function Exports() {
  const navigate = useNavigate();

  const [exportsdata, setExportData] = useState([]);
  const [loading, setLoading] = useState([]);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchExports = async () => {
      try {
        const response = await apiClient.get('/v1/leads/exports');
        // Assuming the leads data is under the "data" key in the response
        setExportData(response.data.exportList);
        setLoading(false);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Redirect to login if unauthorized
          navigate('/login');
        } else {
          console.error('Error fetching data:', error);
        }
        console.error('Error fetching leads:', error);
        setLoading(false);
      }
    }
     
    fetchExports();
  }, []);

  const handleDownload = async (id) => {
    try {
      // Send a request to get the file as a blob
      const response = await apiClient.get(`/v1/leads/export-download/${id}`, {
        responseType: 'blob', // Ensure the response is in blob format for files
      });
  
      // Create a URL for the file blob
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
      const link = document.createElement('a');
      link.href = url;
  
      // Retrieve the file name from the response headers or use a default name with .csv extension
      const contentDisposition = response.headers['content-disposition'];
      let fileName = 'Leads-file.csv'; // Default file name
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
        if (fileNameMatch.length === 2) {
          fileName = fileNameMatch[1];
        }
      }
  
      // Ensure the file name has a .csv extension
      if (!fileName.endsWith('.csv')) {
        fileName += '.csv';
      }
  
      // Set the download attribute with the file name
      link.setAttribute('download', fileName);
      
      // Append link to the body and trigger the click event
      document.body.appendChild(link);
      link.click();
      
      // Clean up the URL object and remove the link from the document
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading the file:', error);
      // Optionally, handle the error with user notification
    }
  };
  
  

  const getClassName = (title) => {
    switch (title) {
      case 'Finished':
        return 'completed-class';
      case 'Failed':
        return 'failed-class';
      case 'Pending':
        return 'pending-class';
    
    }
  };
 
  const formatDateTime = (dateTimeString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    return new Date(dateTimeString).toLocaleDateString('en-US', options);
  };

  return (
    <div className="App" style={{overflow:'hidden'}}>
       <Sidebar />
       <div className='home-body'>
      <span >
      Dashboard > <b>Exports</b>
      
      </span>
      <section className="leads-header">
            <div className='users-head'>
                <div><h1>Exports</h1></div>
                <div className="d-flex justify-content-evenly flex-wrap">
                
                <div className='user-head-content'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 256 256">
	                    <path fill="#000" d="M196.49 151.51a12 12 0 0 1-17 17L168 157v51a12 12 0 0 1-24 0v-51l-11.51 11.52a12 12 0 1 1-17-17l32-32a12 12 0 0 1 17 0ZM160 36a92.08 92.08 0 0 0-81 48.37A68 68 0 1 0 72 220h28a12 12 0 0 0 0-24H72a44 44 0 0 1-1.81-87.95A91.7 91.7 0 0 0 68 128a12 12 0 0 0 24 0a68 68 0 1 1 132.6 21.29a12 12 0 1 0 22.8 7.51A92.06 92.06 0 0 0 160 36" />
                    </svg> Exports
                </div>
                 
                </div>
            </div>
     </section>
       <section className="table-section-home">
          {/* <div className="row mb-2">
              <div className="col-sm-10 table-btn">
                  <div className="table-btn-filter">Show:10 <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                                                              <g fill="none" fill-rule="evenodd">
                                                                  <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                                                                  <path fill="black" d="M12.707 15.707a1 1 0 0 1-1.414 0L5.636 10.05A1 1 0 1 1 7.05 8.636l4.95 4.95l4.95-4.95a1 1 0 0 1 1.414 1.414z" />
                                                              </g>
                                                            </svg>
                  </div>
                  <div className="table-btn-filter">Records:10 <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                                                                  <g fill="none" fill-rule="evenodd">
                                                                      <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                                                                      <path fill="black" d="M12.707 15.707a1 1 0 0 1-1.414 0L5.636 10.05A1 1 0 1 1 7.05 8.636l4.95 4.95l4.95-4.95a1 1 0 0 1 1.414 1.414z" />
                                                                  </g>
                                                              </svg>
                  </div>
                  <div className="table-btn-filter">Per Company:10 <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                                                                      <g fill="none" fill-rule="evenodd">
                                                                          <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                                                                          <path fill="black" d="M12.707 15.707a1 1 0 0 1-1.414 0L5.636 10.05A1 1 0 1 1 7.05 8.636l4.95 4.95l4.95-4.95a1 1 0 0 1 1.414 1.414z" />
                                                                      </g>
                                                                  </svg>
                  </div>
              </div>
              <div className="col-sm-2 table-btn-sort">
            
                  <div className="table-sort-filter">Sort By:Newest <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                                                                      <g fill="none" fill-rule="evenodd">
                                                                          <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                                                                          <path fill="black" d="M12.707 15.707a1 1 0 0 1-1.414 0L5.636 10.05A1 1 0 1 1 7.05 8.636l4.95 4.95l4.95-4.95a1 1 0 0 1 1.414 1.414z" />
                                                                      </g>
                                                                  </svg>
                  </div>
                
              </div>
          </div> */}
          <div className="table-responsive">
            <table className="table">
                <thead>
                <tr>
                    <th className="table-heading truncate">ID</th>
                    <th className="table-heading truncate">Exported Date</th>
                    <th className="table-heading truncate">Status</th>
                    <th className="table-heading truncate">Download</th>
                   
                </tr>
                </thead>
                <tbody>
                {exportsdata.map((exports) => (
                    <tr key={exports.id}>
                    <td className="table-body">{exports.id}</td>
                    <td className="table-body">{formatDateTime(exports.created_at)}</td>
                    <td className="table-body"><Link to='/subscription'><div className={` ${getClassName(exports.status)}`}>{exports.status}</div></Link></td>
                    <td className="table-body">
                      <a href='#' onClick={() => handleDownload(exports.id)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                            <g fill="none" fill-rule="evenodd">
                                <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                                <path fill="orange" d="M11.5 4a4.5 4.5 0 0 1 4.344 3.32c.104.383.421.67.811.733A4.001 4.001 0 0 1 18 15.466a1 1 0 1 0 1.002 1.73a6.002 6.002 0 0 0-1.415-10.985A6.502 6.502 0 0 0 5 8.417a5 5 0 0 0 .124 9.22a1 1 0 0 0 .75-1.854a3.001 3.001 0 0 1 .394-5.692A1.01 1.01 0 0 0 7.027 9C6.74 6.38 8.851 4 11.5 4m.5 7a1 1 0 0 1 1 1v6.584l1.293-1.292a1 1 0 0 1 1.414 1.416l-2.824 2.819c-.253.252-.5.473-.883.473c-.384 0-.63-.22-.883-.473l-2.824-2.82a1 1 0 0 1 1.414-1.415L11 18.584V12a1 1 0 0 1 1-1" />
                            </g>
                        </svg>
                      </a>
                    </td>
                   
                    </tr>
                ))}
                </tbody>
            </table>
            </div>

        </section>
      </div>
      
    </div>
  );
}

export default Exports;



