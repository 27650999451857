import React, { useEffect, useState } from "react";
import feather from "feather-icons";
import { useNavigate } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";

import "./App.css";
import "./sidebar.css";
import apiClient from './apiClient'; // Import the custom axios instance

import profile from './assets/profile.JPG'


function Sidebar() {

  const navigate = useNavigate();

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('Overview'); // State to track active tab
  const location = useLocation();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  const handleLogout = async () => {
    try {
      // Perform the logout API call
      await apiClient.post('/v1/logout');
      
      // Navigate to the login page after successful logout
      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
      
      // Handle the 401 error or any other error as needed
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
    }
  };

  useEffect(() => {
    const path = location.pathname;
    switch (path) {
      case '/leads':
        setActiveTab('Leads');
        break;
      case '/users':
        setActiveTab('Users');
        break;
      case '/exports':
        setActiveTab('Exports');
        break;
      default:
        setActiveTab('Overview');
    }
  }, [location.pathname]);

  return (
    <div>
      <div
        className={`offcanvas sidebar-offcanvas offcanvas-start${
          isSidebarOpen ? " show" : ""
        }`}
        tabIndex={-1}
        id="offcanvasLeft"
      >
        <div className="offcanvas-header sidebar-header">
          <a onClick={closeSidebar}>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	            <path fill="#000" d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z" />
            </svg>
          </a>

          <h2 className="navbar-header">REVENUEWAVE</h2>

         
        </div>
        <div className="offcanvas-body">
          <div className="sidebar-content ">
            <ul className="link-section ">
              <li className="sidebar-contain">
                <Link to="/" className={`pages sidebar-contain ${activeTab === 'Overview' ? 'active' : ''}`} onClick={() => setActiveTab('Overview')}>
                <div className='sidebar-icon'>
                        {activeTab === 'Overview' ? <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><g fill="none" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path d="M6.133 21C4.955 21 4 20.02 4 18.81v-8.802c0-.665.295-1.295.8-1.71l5.867-4.818a2.09 2.09 0 0 1 2.666 0l5.866 4.818c.506.415.801 1.045.801 1.71v8.802c0 1.21-.955 2.19-2.133 2.19z"/>
                        <path d="M9.5 21v-5.5a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2V21"/></g></svg> : <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><g fill="none" stroke="#5800ac" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path d="M6.133 21C4.955 21 4 20.02 4 18.81v-8.802c0-.665.295-1.295.8-1.71l5.867-4.818a2.09 2.09 0 0 1 2.666 0l5.866 4.818c.506.415.801 1.045.801 1.71v8.802c0 1.21-.955 2.19-2.133 2.19z"/>
                        <path d="M9.5 21v-5.5a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2V21"/></g></svg>}
                    </div> 
                    Overview
                </Link>
              </li>
              <li className="sidebar-contain">
                <Link to="/leads" className={`pages sidebar-contain ${activeTab === 'Leads' ? 'active' : ''}`} onClick={() => setActiveTab('Leads')}>
                <div className='sidebar-icon'>
                        {activeTab === 'Leads' ? <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24">
                        <path fill="white" fill-rule="evenodd" d="M22 6.99V11a1 1 0 0 1-1.707.707L19 10.414l-5.293 5.293a1 1 0 0 1-1.414 0L9 12.414l-5.293 5.293a1 1 0 0 1-1.414-1.414l6-6a1 1 0 0 1 1.414 0L13 13.586L17.586 9l-1.293-1.293A1 1 0 0 1 17 6h4l.048.001a.996.996 0 0 1 .952.99" clip-rule="evenodd"/></svg> :<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24">
                        <path fill="#5800ac" fill-rule="evenodd" d="M22 6.99V11a1 1 0 0 1-1.707.707L19 10.414l-5.293 5.293a1 1 0 0 1-1.414 0L9 12.414l-5.293 5.293a1 1 0 0 1-1.414-1.414l6-6a1 1 0 0 1 1.414 0L13 13.586L17.586 9l-1.293-1.293A1 1 0 0 1 17 6h4l.048.001a.996.996 0 0 1 .952.99" clip-rule="evenodd"/></svg>}
                    </div> 
                    Leads
                </Link>
              </li>
              {/* <li className="sidebar-contain">
                <Link to="/users" className={`pages sidebar-contain ${activeTab === 'Users' ? 'active' : ''}`} onClick={() => setActiveTab('Users')}>
                <div className='sidebar-icon'>
                        {activeTab === 'Users' ? <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 15 15">
                        <path fill="white" d="M5.5 0a3.499 3.499 0 1 0 0 6.996A3.499 3.499 0 1 0 5.5 0m-2 8.994a3.5 3.5 0 0 0-3.5 3.5v2.497h11v-2.497a3.5 3.5 0 0 0-3.5-3.5zm9 1.006H12v5h3v-2.5a2.5 2.5 0 0 0-2.5-2.5"/>
                        <path fill="white" d="M11.5 4a2.5 2.5 0 1 0 0 5a2.5 2.5 0 0 0 0-5"/></svg> : <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 15 15">
                        <path fill="#5800ac" d="M5.5 0a3.499 3.499 0 1 0 0 6.996A3.499 3.499 0 1 0 5.5 0m-2 8.994a3.5 3.5 0 0 0-3.5 3.5v2.497h11v-2.497a3.5 3.5 0 0 0-3.5-3.5zm9 1.006H12v5h3v-2.5a2.5 2.5 0 0 0-2.5-2.5"/>
                        <path fill="#5800ac" d="M11.5 4a2.5 2.5 0 1 0 0 5a2.5 2.5 0 0 0 0-5"/></svg>}
                    </div> 
                    Users
                </Link>
              </li> */}
              <li className="sidebar-contain">
                <Link to="/exports" className={`pages sidebar-contain ${activeTab === 'Exports' ? 'active' : ''}`} onClick={() => setActiveTab('Exports')}>
                <div className='sidebar-icon'>
                        {activeTab === 'Exports' ? <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 16 16"><path fill="white" fill-rule="evenodd" d="M11.78 5.841a.75.75 0 0 1-1.06 0l-1.97-1.97v7.379a.75.75 0 0 1-1.5 0V3.871l-1.97 1.97a.75.75 0 0 1-1.06-1.06l3.25-3.25L8 1l.53.53l3.25 3.25a.75.75 0 0 1 0 1.061M2.5 9.75a.75.75 0 0 0-1.5 0V13a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V9.75a.75.75 0 0 0-1.5 0V13a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5z" clip-rule="evenodd"/></svg> :<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 16 16">
                        <path fill="#5800ac" fill-rule="evenodd" d="M11.78 5.841a.75.75 0 0 1-1.06 0l-1.97-1.97v7.379a.75.75 0 0 1-1.5 0V3.871l-1.97 1.97a.75.75 0 0 1-1.06-1.06l3.25-3.25L8 1l.53.53l3.25 3.25a.75.75 0 0 1 0 1.061M2.5 9.75a.75.75 0 0 0-1.5 0V13a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V9.75a.75.75 0 0 0-1.5 0V13a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5z" clip-rule="evenodd"/></svg>}
                    </div>  
                    Exports
                </Link>
              </li>
              {/* <li className="sidebar-contain">
              <div className="flag sidebar-flag">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 512 512">
              <mask id="circleFlagsUs0"><circle cx="256" cy="256" r="256" fill="#fff"/></mask>
              <g mask="url(#circleFlagsUs0)">
                <path fill="#eee" d="M256 0h256v64l-32 32l32 32v64l-32 32l32 32v64l-32 32l32 32v64l-256 32L0 448v-64l32-32l-32-32v-64z"/>
                <path fill="#d80027" d="M224 64h288v64H224Zm0 128h288v64H256ZM0 320h512v64H0Zm0 128h512v64H0Z"/>
                <path fill="#0052b4" d="M0 0h256v256H0Z"/>
                <path fill="#eee" d="m187 243l57-41h-70l57 41l-22-67zm-81 0l57-41H93l57 41l-22-67zm-81 0l57-41H12l57 41l-22-67zm162-81l57-41h-70l57 41l-22-67zm-81 0l57-41H93l57 41l-22-67zm-81 0l57-41H12l57 41l-22-67Zm162-82l57-41h-70l57 41l-22-67Zm-81 0l57-41H93l57 41l-22-67zm-81 0l57-41H12l57 41l-22-67Z"/>
              </g>
            </svg>
            <div className="language">Eng (US)</div>
            <div className="arrow-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 7">
                <path fill="black" d="M8 6.5a.47.47 0 0 1-.35-.15l-4.5-4.5c-.2-.2-.2-.51 0-.71s.51-.2.71 0l4.15 4.15l4.14-4.14c.2-.2.51-.2.71 0s.2.51 0 .71l-4.5 4.5c-.1.1-.23.15-.35.15Z"/>
              </svg>
            </div>
          </div>
          </li>
          <li><div className='user-profile1'>
           <img className='profile-image1' src={profile}></img>
           <div className='user-name'>
                <h4 className='name'>Faisal</h4>
                <p className='role'>Admin</p>
           </div>
           <div className='arrow-icon'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 7">
                    <path fill="black" d="M8 6.5a.47.47 0 0 1-.35-.15l-4.5-4.5c-.2-.2-.2-.51 0-.71s.51-.2.71 0l4.15 4.15l4.14-4.14c.2-.2.51-.2.71 0s.2.51 0 .71l-4.5 4.5c-.1.1-.23.15-.35.15Z"/>
                </svg>
            </div>
        </div></li> */}
              <li className="sidebar-contain">
                <Link to="#" className="pages sidebar-contain" onClick={handleLogout}>
                  <div className='sidebar-icon'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                      <g fill="none" stroke="#5800ac" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                        <path d="M14 8V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2v-2"/>
                        <path d="M9 12h12l-3-3m0 6l3-3"/>
                      </g>
                    </svg>
                  </div>
                  Sign out
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <header className="section-t-space" style={{paddingTop:'10px'}}>
        <div className="custom-container">
          <div className="header-panel" style={{height:'80px'}}>
            <button className="sidebar-btn" onClick={toggleSidebar}>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                    <path fill="none" stroke="black" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 6h18M3 12h18M3 18h18"/>
                </svg>
            </button>
            <div className="input-search">
            <input type="text" placeholder="Search.... " required="" />
            <span className="input-toggle">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 512 512">
                    <path fill="#757575" d="M464 428L339.92 303.9a160.48 160.48 0 0 0 30.72-94.58C370.64 120.37 298.27 48 209.32 48S48 120.37 48 209.32s72.37 161.32 161.32 161.32a160.48 160.48 0 0 0 94.58-30.72L428 464ZM209.32 319.69a110.38 110.38 0 1 1 110.37-110.37a110.5 110.5 0 0 1-110.37 110.37"/>
                </svg>
            </span>
        </div>
        <div className="flag navbar-flag">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 512 512">
                <mask id="circleFlagsUs0"><circle cx="256" cy="256" r="256" fill="#fff"/></mask>
                <g mask="url(#circleFlagsUs0)">
                  <path fill="#eee" d="M256 0h256v64l-32 32l32 32v64l-32 32l32 32v64l-32 32l32 32v64l-256 32L0 448v-64l32-32l-32-32v-64z"/>
                  <path fill="#d80027" d="M224 64h288v64H224Zm0 128h288v64H256ZM0 320h512v64H0Zm0 128h512v64H0Z"/>
                  <path fill="#0052b4" d="M0 0h256v256H0Z"/>
                  <path fill="#eee" d="m187 243l57-41h-70l57 41l-22-67zm-81 0l57-41H93l57 41l-22-67zm-81 0l57-41H12l57 41l-22-67zm162-81l57-41h-70l57 41l-22-67zm-81 0l57-41H93l57 41l-22-67zm-81 0l57-41H12l57 41l-22-67Zm162-82l57-41h-70l57 41l-22-67Zm-81 0l57-41H93l57 41l-22-67zm-81 0l57-41H12l57 41l-22-67Z"/>
                </g>
              </svg>
              <div className="language">Eng (US)</div>
              <div className="arrow-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 7">
                  <path fill="black" d="M8 6.5a.47.47 0 0 1-.35-.15l-4.5-4.5c-.2-.2-.2-.51 0-.71s.51-.2.71 0l4.15 4.15l4.14-4.14c.2-.2.51-.2.71 0s.2.51 0 .71l-4.5 4.5c-.1.1-.23.15-.35.15Z"/>
                </svg>
              </div>
            </div>
        <div className='date'>
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 20 20">
                <path fill="black" d="M5.673 0a.7.7 0 0 1 .7.7v1.309h7.517v-1.3a.7.7 0 0 1 1.4 0v1.3H18a2 2 0 0 1 2 1.999v13.993A2 2 0 0 1 18 20H2a2 2 0 0 1-2-1.999V4.008a2 2 0 0 1 2-1.999h2.973V.699a.7.7 0 0 1 .7-.699M1.4 7.742v10.259a.6.6 0 0 0 .6.6h16a.6.6 0 0 0 .6-.6V7.756zm5.267 6.877v1.666H5v-1.666zm4.166 0v1.666H9.167v-1.666zm4.167 0v1.666h-1.667v-1.666zm-8.333-3.977v1.666H5v-1.666zm4.166 0v1.666H9.167v-1.666zm4.167 0v1.666h-1.667v-1.666zM4.973 3.408H2a.6.6 0 0 0-.6.6v2.335l17.2.014V4.008a.6.6 0 0 0-.6-.6h-2.71v.929a.7.7 0 0 1-1.4 0v-.929H6.373v.92a.7.7 0 0 1-1.4 0z"/>
            </svg>
        </div>
        <div className='message'>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                <path fill="none" stroke="black" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 11v.01M8 11v.01m8-.01v.01M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3h-5l-5 3v-3H6a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3z"/>
            </svg>
        </div>
        <div className='notification1'>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 32 32">
                <path fill="black" d="M28.707 19.293L26 16.586V13a10.014 10.014 0 0 0-9-9.95V1h-2v2.05A10.014 10.014 0 0 0 6 13v3.586l-2.707 2.707A1 1 0 0 0 3 20v3a1 1 0 0 0 1 1h7v.777a5.15 5.15 0 0 0 4.5 5.199A5.006 5.006 0 0 0 21 25v-1h7a1 1 0 0 0 1-1v-3a1 1 0 0 0-.293-.707M19 25a3 3 0 0 1-6 0v-1h6Zm8-3H5v-1.586l2.707-2.707A1 1 0 0 0 8 17v-4a8 8 0 0 1 16 0v4a1 1 0 0 0 .293.707L27 20.414Z"/>
            </svg>
        </div>
        <div className='user-profile'>
           <img className='profile-image' src={profile}></img>
           <div className='user-name'>
                <h4 className='name'>Faisal</h4>
                <p className='role'>Admin</p>
           </div>
           <div className='arrow-icon'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 7">
                    <path fill="black" d="M8 6.5a.47.47 0 0 1-.35-.15l-4.5-4.5c-.2-.2-.2-.51 0-.71s.51-.2.71 0l4.15 4.15l4.14-4.14c.2-.2.51-.2.71 0s.2.51 0 .71l-4.5 4.5c-.1.1-.23.15-.35.15Z"/>
                </svg>
            </div>
        </div>
                          
          </div>
        </div>
      </header>
    </div>
  );
}

export default Sidebar;
