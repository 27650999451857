import './login.css';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import axios from "axios";

function Login() {
    const navigate = useNavigate();

    const [showEmailname, setshowEmailname] = useState(false);
    const [showpassname, setshowpassname] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);    
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
 
     
    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission
        setLoading(true);
        setError(null); // Clear previous errors
    
        try {
          const response = await axios.post(`${apiBaseUrl}v1/login`, {
            email,
            password,
          });
    
          const token = response.data.access_token.token;
          const tokenType = response.data.access_token.type;
    
          // Store the token and token type
          localStorage.setItem('access_token', token);
          localStorage.setItem('token_type', tokenType);
    
          console.log("Login successful, token stored.");
    
          navigate('/'); // Navigate to the home page on successful login
        } catch (error) {
          setError(error.response ? error.response.data.message : error.message);
          console.error("Error logging in:", error);
        } finally {
          setLoading(false);
          console.log("Login process complete");
        }
      };



    const handleemail = () => {
        setshowEmailname(true);
    }

    const handlepassword = () => {
        setshowpassname(true);
    }
  return (
    <div class="container">
        <div className="login-form">
            <div className='login-header'>Welcome to</div>
                <h3 className='login-name'><b>REVENUEWAVE</b></h3>

            <div className='google-btn'>
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 128 128">
                        <path fill="#fff" d="M44.59 4.21a63.28 63.28 0 0 0 4.33 120.9a67.6 67.6 0 0 0 32.36.35a57.13 57.13 0 0 0 25.9-13.46a57.44 57.44 0 0 0 16-26.26a74.3 74.3 0 0 0 1.61-33.58H65.27v24.69h34.47a29.72 29.72 0 0 1-12.66 19.52a36.2 36.2 0 0 1-13.93 5.5a41.3 41.3 0 0 1-15.1 0A37.2 37.2 0 0 1 44 95.74a39.3 39.3 0 0 1-14.5-19.42a38.3 38.3 0 0 1 0-24.63a39.25 39.25 0 0 1 9.18-14.91A37.17 37.17 0 0 1 76.13 27a34.3 34.3 0 0 1 13.64 8q5.83-5.8 11.64-11.63c2-2.09 4.18-4.08 6.15-6.22A61.2 61.2 0 0 0 87.2 4.59a64 64 0 0 0-42.61-.38"/>
                        <path fill="#e33629" d="M44.59 4.21a64 64 0 0 1 42.61.37a61.2 61.2 0 0 1 20.35 12.62c-2 2.14-4.11 4.14-6.15 6.22Q95.58 29.23 89.77 35a34.3 34.3 0 0 0-13.64-8a37.17 37.17 0 0 0-37.46 9.74a39.25 39.25 0 0 0-9.18 14.91L8.76 35.6A63.53 63.53 0 0 1 44.59 4.21"/>
                        <path fill="#f8bd00" d="M3.26 51.5a63 63 0 0 1 5.5-15.9l20.73 16.09a38.3 38.3 0 0 0 0 24.63q-10.36 8-20.73 16.08a63.33 63.33 0 0 1-5.5-40.9"/>
                        <path fill="#587dbd" d="M65.27 52.15h59.52a74.3 74.3 0 0 1-1.61 33.58a57.44 57.44 0 0 1-16 26.26c-6.69-5.22-13.41-10.4-20.1-15.62a29.72 29.72 0 0 0 12.66-19.54H65.27c-.01-8.22 0-16.45 0-24.68"/>
                        <path fill="#319f43" d="M8.75 92.4q10.37-8 20.73-16.08A39.3 39.3 0 0 0 44 95.74a37.2 37.2 0 0 0 14.08 6.08a41.3 41.3 0 0 0 15.1 0a36.2 36.2 0 0 0 13.93-5.5c6.69 5.22 13.41 10.4 20.1 15.62a57.13 57.13 0 0 1-25.9 13.47a67.6 67.6 0 0 1-32.36-.35a63 63 0 0 1-23-11.59A63.7 63.7 0 0 1 8.75 92.4"/>
                    </svg>
                </span>
                <span style={{margin:" 0 0 0 5px"}}> 
                     Login with Google 
                </span>
            </div>
            <div className='google-btn'>
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 16 16">
                        <path fill="#1769aa" d="M7.2 16V8.5h-2V5.8h2V3.5C7.2 1.7 8.4 0 11.1 0c1.1 0 1.9.1 1.9.1l-.1 2.5h-1.7c-1 0-1.1.4-1.1 1.2v2H13l-.1 2.7h-2.8V16z"/>
                    </svg>
                </span>
                <span style={{margin:" 0 0 0 5px"}}>
                    Login with Facebook 
                </span>
            </div>
            <div class="or-divider">
                <span class="line"></span>
                <span class="text">OR</span>
                <span class="line"></span>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="input-group1">
                    <span className="input-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                        <g fill="#5800ac">
                        <path d="M2.038 5.61A2 2 0 0 0 2 6v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6q0-.18-.03-.352l-.866.65l-7.89 6.032a2 2 0 0 1-2.429 0L2.884 6.288l-.846-.677Z"/>
                        <path d="M20.677 4.117A2 2 0 0 0 20 4H4q-.338.002-.642.105l.758.607L12 10.742L19.9 4.7z"/>
                        </g>
                    </svg>
                    </span>
                    <input 
                    type="email" 
                    placeholder="Email" 
                    required 
                    onClick={() => setshowEmailname(true)} 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className="input-group1">
                    <span className="input-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                        <path fill="#5800ac" fillRule="evenodd" d="M22 8.293c0 3.476-2.83 6.294-6.32 6.294c-.636 0-2.086-.146-2.791-.732l-.882.878c-.519.517-.379.669-.148.919c.096.105.208.226.295.399c0 0 .735 1.024 0 2.049c-.441.585-1.676 1.404-3.086 0l-.294.292s.881 1.025.147 2.05c-.441.585-1.617 1.17-2.646.146l-1.028 1.024c-.706.703-1.568.293-1.91 0l-.883-.878c-.823-.82-.343-1.708 0-2.05l7.642-7.61s-.735-1.17-.735-2.78c0-3.476 2.83-6.294 6.32-6.294S22 4.818 22 8.293m-6.319 2.196a2.2 2.2 0 0 0 2.204-2.195a2.2 2.2 0 0 0-2.204-2.196a2.2 2.2 0 0 0-2.204 2.196a2.2 2.2 0 0 0 2.204 2.195" clipRule="evenodd"/>
                    </svg>
                    </span>
                    <input 
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                required 
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <span className="input-toggle" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
                        <path fill="#5800ac" d="M2.22 2.22a.75.75 0 0 0-.073.976l.073.084l4.034 4.035a10 10 0 0 0-3.955 5.75a.75.75 0 0 0 1.455.364a8.5 8.5 0 0 1 3.58-5.034l1.81 1.81A4 4 0 0 0 14.8 15.86l5.919 5.92a.75.75 0 0 0 1.133-.977l-.073-.084l-6.113-6.114l.001-.002l-6.95-6.946l.002-.002l-1.133-1.13L3.28 2.22a.75.75 0 0 0-1.06 0M12 5.5c-1 0-1.97.148-2.889.425l1.237 1.236a8.503 8.503 0 0 1 9.899 6.272a.75.75 0 0 0 1.455-.363A10 10 0 0 0 12 5.5m.195 3.51l3.801 3.8a4.003 4.003 0 0 0-3.801-3.8" />
                    </svg>
                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
                        <g fill="none" stroke="#5800ac" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
                            <path d="M3 13c3.6-8 14.4-8 18 0" />
                            <path fill="#5800ac" d="M12 17a3 3 0 1 1 0-6a3 3 0 0 1 0 6" />
                        </g>
                    </svg>
                )}
                    </span>
                </div>
                <div className="options">
                    <label>
                    <input type="checkbox" /> Remember me
                    </label>
                    <a href="#" className="forgot-password">
                    Forgot Password?
                    </a>
                </div>
                <button type="submit" className="login-button" disabled={loading}>
                    {loading ? 'Logging in...' : 'Login'}
                </button>
                {error && <p className="error-message">Error: {error}</p>}
                <div className="register-link">
                    Don't have an account? <a href="/register">Register</a>
                </div>
            </form>


        </div>
    </div>
  );
}

export default Login;
