
import feather from "feather-icons";
import Sidebar from "./Sidebar";

import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import Login from "./login";
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import profile from './assets/profile.JPG'

import { Doughnut } from "react-chartjs-2";

import { Link } from "react-router-dom";


import "./App.css";


import { useEffect } from "react";
import UserForm from "./userForm";
import Modal from "./modal";




function Users() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const tableData = [
    { name: 'John Doe',title: 'John Doe', company: 'ABC Inc',phone: '1234567890', email: 'john.doe@example.com',country: 'USA',Address: 'USA' },
    { name: 'John Doe',title: 'John Doe', company: 'ABC Inc',phone: '1234567890', email: 'john.doe@example.com',country: 'USA',Address: 'USA' },
    { name: 'John Doe',title: 'John Doe', company: 'ABC Inc',phone: '1234567890', email: 'john.doe@example.com',country: 'USA',Address: 'USA' },
    { name: 'John Doe',title: 'John Doe', company: 'ABC Inc',phone: '1234567890', email: 'john.doe@example.com',country: 'USA',Address: 'USA' },
    { name: 'John Doe',title: 'John Doe', company: 'ABC Inc',phone: '1234567890', email: 'john.doe@example.com',country: 'USA',Address: 'USA' },
    { name: 'John Doe',title: 'John Doe', company: 'ABC Inc',phone: '1234567890', email: 'john.doe@example.com',country: 'USA',Address: 'USA' },
    { name: 'John Doe',title: 'John Doe', company: 'ABC Inc',phone: '1234567890', email: 'john.doe@example.com',country: 'USA',Address: 'USA' },
    // Add more data as needed
  ];
 
  return (
    <div className="App" style={{overflow:'hidden'}}>
       <Sidebar />
       <Modal show={showModal} onClose={handleCloseModal} title="Add Users">
        <UserForm  />
      </Modal>
       <div className='home-body'>
      <span >
      Dashboard > <b>users</b>
      
      </span>
      <section className="leads-header">
            <div className='users-head'>
                <div><h1>Users</h1></div>
                <div className="d-flex justify-content-evenly flex-wrap">
                <div className='user-head-content' >
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	                    <path fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 14.899A7 7 0 1 1 15.71 8h1.79a4.5 4.5 0 0 1 2.5 8.242M12 12v9m-4-4l4 4l4-4" />
                    </svg> Import
                </div>
                <div className='user-head-content'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 256 256">
	                    <path fill="#000" d="M196.49 151.51a12 12 0 0 1-17 17L168 157v51a12 12 0 0 1-24 0v-51l-11.51 11.52a12 12 0 1 1-17-17l32-32a12 12 0 0 1 17 0ZM160 36a92.08 92.08 0 0 0-81 48.37A68 68 0 1 0 72 220h28a12 12 0 0 0 0-24H72a44 44 0 0 1-1.81-87.95A91.7 91.7 0 0 0 68 128a12 12 0 0 0 24 0a68 68 0 1 1 132.6 21.29a12 12 0 1 0 22.8 7.51A92.06 92.06 0 0 0 160 36" />
                    </svg> Exports
                </div>
                <div className='user-add-btn ' onClick={handleOpenModal}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	                    <path fill="white" d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6z" /> 
                    </svg> Add New User
                </div> 
                </div>
            </div>
     </section>
       <section className="table-section-home">
<div className="row mb-2">
    <div className="col-sm-10 table-btn">
        <div className="table-btn-filter">Show:10 <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                                                    <g fill="none" fill-rule="evenodd">
                                                        <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                                                        <path fill="black" d="M12.707 15.707a1 1 0 0 1-1.414 0L5.636 10.05A1 1 0 1 1 7.05 8.636l4.95 4.95l4.95-4.95a1 1 0 0 1 1.414 1.414z" />
                                                    </g>
                                                  </svg>
        </div>
        <div className="table-btn-filter">Records:10 <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                                                        <g fill="none" fill-rule="evenodd">
                                                            <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                                                            <path fill="black" d="M12.707 15.707a1 1 0 0 1-1.414 0L5.636 10.05A1 1 0 1 1 7.05 8.636l4.95 4.95l4.95-4.95a1 1 0 0 1 1.414 1.414z" />
                                                        </g>
                                                    </svg>
        </div>
        <div className="table-btn-filter">Per Company:10 <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                                                            <g fill="none" fill-rule="evenodd">
                                                                <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                                                                <path fill="black" d="M12.707 15.707a1 1 0 0 1-1.414 0L5.636 10.05A1 1 0 1 1 7.05 8.636l4.95 4.95l4.95-4.95a1 1 0 0 1 1.414 1.414z" />
                                                            </g>
                                                        </svg>
        </div>
    </div>
    <div className="col-sm-2 table-btn-sort">
   
        <div className="table-sort-filter">Sort By:Newest <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                                                            <g fill="none" fill-rule="evenodd">
                                                                <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                                                                <path fill="black" d="M12.707 15.707a1 1 0 0 1-1.414 0L5.636 10.05A1 1 0 1 1 7.05 8.636l4.95 4.95l4.95-4.95a1 1 0 0 1 1.414 1.414z" />
                                                            </g>
                                                        </svg>
        </div>
       
    </div>
</div>
<div className="table-responsive">
            <table className="table">
                <thead>
                <tr>
                    <th><input type="checkbox" /></th>
                    <th className="table-heading">Name</th>
                    <th className="table-heading">Title</th>
                    <th className="table-heading">Company</th>
                    <th className="table-heading">Phone Number</th>
                    <th className="table-heading">Email</th>
                    <th className="table-heading">Links</th>
                    <th className="table-heading">Country</th>
                    <th className="table-heading">Address</th>
                </tr>
                </thead>
                <tbody>
                {tableData.map((row) => (
                    <tr key={row.id}>
                    <td className="table-body"><input type="checkbox" /></td>
                    <td className="table-body"><img className="table-image" src={profile} alt="profile"/> {row.name}</td>
                    <td className="table-body">{row.title}</td>
                    <td className="table-body">{row.company}</td>
                    <td className="table-body"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20">
                            <path fill="black" d="m6.987 2.066l-.717.216a3.5 3.5 0 0 0-2.454 2.854c-.297 2.068.367 4.486 1.968 7.259c1.597 2.766 3.355 4.548 5.29 5.328a3.5 3.5 0 0 0 3.715-.705l.542-.514a2 2 0 0 0 .247-2.623l-1.356-1.88a1.5 1.5 0 0 0-1.655-.556l-2.051.627l-.053.01c-.226.033-.748-.456-1.398-1.582c-.68-1.178-.82-1.867-.633-2.045l1.043-.973a2.5 2.5 0 0 0 .575-2.85l-.662-1.471a2 2 0 0 0-2.4-1.095m1.49 1.505l.66 1.471a1.5 1.5 0 0 1-.344 1.71l-1.046.974C7.078 8.36 7.3 9.442 8.2 11c.846 1.466 1.618 2.19 2.448 2.064l.124-.026l2.088-.637a.5.5 0 0 1 .552.185l1.356 1.88a1 1 0 0 1-.123 1.312l-.543.514a2.5 2.5 0 0 1-2.653.503c-1.698-.684-3.303-2.311-4.798-4.9C5.152 9.3 4.545 7.093 4.806 5.278a2.5 2.5 0 0 1 1.753-2.039l.717-.216a1 1 0 0 1 1.2.548" />
                        </svg> {row.phone}
                    </td>
                    <td className="table-body"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
                            <path fill="black" d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2zm-2 0l-8 5l-8-5zm0 12H4V8l8 5l8-5z" />
                        </svg> {row.email}
                    </td>
                    <td className="table-body">
                               
                                   <span className="mr-6"> 
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="-2 -2 24 24">
                                            <g fill="#2f88ff">
                                                <path d="M15 11.13v3.697h-2.143v-3.45c0-.866-.31-1.457-1.086-1.457c-.592 0-.945.398-1.1.784c-.056.138-.071.33-.071.522v3.601H8.456s.029-5.842 0-6.447H10.6v.913l-.014.021h.014v-.02c.285-.44.793-1.066 1.932-1.066c1.41 0 2.468.922 2.468 2.902M6.213 5.271C5.48 5.271 5 5.753 5 6.385c0 .62.466 1.115 1.185 1.115h.014c.748 0 1.213-.496 1.213-1.115c-.014-.632-.465-1.114-1.199-1.114m-1.086 9.556h2.144V8.38H5.127z" />
                                                <path d="M10 18a8 8 0 1 0 0-16a8 8 0 0 0 0 16m0 2C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10s-4.477 10-10 10" />
                                            </g>
                                        </svg>
                                    </span>
                                    <span className="mr-6">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 20 20">
                                            <g fill="#2f88ff">
                                                <path fill-rule="evenodd" d="M1.25 10a8.75 8.75 0 1 0 17.5 0a8.75 8.75 0 0 0-17.5 0m16 0a7.25 7.25 0 1 1-14.5 0a7.25 7.25 0 0 1 14.5 0" clip-rule="evenodd" />
                                                <path fill-rule="evenodd" d="M6.25 10c0 4.522 1.491 8.25 3.75 8.25s3.75-3.728 3.75-8.25S12.259 1.75 10 1.75S6.25 5.478 6.25 10m6 0c0 3.762-1.195 6.75-2.25 6.75S7.75 13.762 7.75 10S8.945 3.25 10 3.25s2.25 2.988 2.25 6.75" clip-rule="evenodd" />
                                                <path d="m3.602 5.467l1.006-1.112c.1.09.209.18.325.267c1.271.952 3.3 1.54 5.515 1.54c1.891 0 3.653-.427 4.931-1.158c.308-.176.582-.367.819-.57l.974 1.141a6.73 6.73 0 0 1-1.048.73c-1.516.868-3.534 1.356-5.676 1.356c-2.522 0-4.865-.678-6.415-1.839a6.063 6.063 0 0 1-.431-.355m0 9.082l1.006 1.112c.1-.091.209-.18.325-.267c1.271-.952 3.3-1.54 5.515-1.54c1.891 0 3.653.427 4.931 1.158c.308.176.582.367.819.57l.974-1.141a6.841 6.841 0 0 0-1.048-.73c-1.516-.868-3.534-1.356-5.676-1.356c-2.522 0-4.865.678-6.415 1.839a6.06 6.06 0 0 0-.431.355M1.75 10.75v-1.5h16.5v1.5z" />
                                            </g>
                                        </svg>
                                    </span>
                                    <span className="mr-6">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 32 32">
                                            <g fill="#2f88ff">
                                                <path d="M16-.034C7.159-.034-.035 7.158-.035 16S7.159 32.034 16 32.034S32.035 24.842 32.035 16S24.841-.034 16-.034m0 31C7.748 30.966 1.035 24.252 1.035 16S7.748 1.034 16 1.034S30.965 7.748 30.965 16S24.252 30.966 16 30.966" />
                                                <path d="M24.794 11.046a3.73 3.73 0 0 0 .273-.643a.535.535 0 0 0-.781-.623a6.033 6.033 0 0 1-1.646.673a3.831 3.831 0 0 0-2.562-.987a3.82 3.82 0 0 0-3.814 3.973a8.821 8.821 0 0 1-5.735-3.178a.536.536 0 0 0-.876.07a3.813 3.813 0 0 0-.066 3.721a.628.628 0 0 0-.214.078a.571.571 0 0 0-.264.481c0 1.135.505 2.177 1.319 2.885l-.035.035a.534.534 0 0 0-.108.518a3.816 3.816 0 0 0 2.184 2.365a6.134 6.134 0 0 1-3.406.579a.545.545 0 0 0-.566.352a.536.536 0 0 0 .215.63a9.841 9.841 0 0 0 5.319 1.559c6.18 0 9.874-5.02 9.874-9.873l-.001-.164a7.122 7.122 0 0 0 1.541-1.66a.535.535 0 0 0-.651-.791m-1.745 1.755a.535.535 0 0 0-.222.461c.006.132.009.266.009.398c0 4.328-3.293 8.805-8.804 8.805a8.76 8.76 0 0 1-2.887-.488a7.073 7.073 0 0 0 3.045-1.39a.535.535 0 0 0-.32-.955a2.752 2.752 0 0 1-2.194-1.162c.253-.017.501-.059.742-.124a.534.534 0 0 0-.036-1.04a2.741 2.741 0 0 1-2.081-1.905c.266.067.539.106.812.115c.235-.001.453-.145.526-.371s-.013-.475-.212-.607a2.746 2.746 0 0 1-1.143-2.945a9.883 9.883 0 0 0 6.568 2.972c.176.001.33-.062.437-.191s.148-.3.11-.464a2.75 2.75 0 0 1 4.682-2.506a.535.535 0 0 0 .494.159c.165-.033.328-.071.489-.115a2.538 2.538 0 0 1-.226.151a.535.535 0 0 0 .338.988l.182-.023a5.605 5.605 0 0 1-.309.237" />
                                            </g>
                                        </svg>
                                    </span>
                                    <span className="mr-6">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                                            <g fill="none" fill-rule="evenodd">
                                                <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                                                <path fill="#2f88ff" d="M4 12a8 8 0 1 1 9 7.938V14h2a1 1 0 1 0 0-2h-2v-2a1 1 0 0 1 1-1h.5a1 1 0 1 0 0-2H14a3 3 0 0 0-3 3v2H9a1 1 0 1 0 0 2h2v5.938A8 8 0 0 1 4 12m8 10c5.523 0 10-4.477 10-10S17.523 2 12 2S2 6.477 2 12s4.477 10 10 10" />
                                            </g>
                                        </svg>
                                    </span>
                    </td>
                    <td className="table-body">{row.country}</td>
                    <td className="table-body">{row.Address}</td>
                  
                    </tr>
                ))}
                </tbody>
            </table>
            </div>

        </section>
      </div>
    </div>
  );
}

export default Users;



