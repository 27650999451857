// ImportForm.js
import { Textarea } from '@material-tailwind/react';
import React, { useState } from 'react';

const UserForm = ({ onSubmit }) => {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(file);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        
        <div className='row p-2'>
             <div className='col-sm-6 mb-2 font-change1'>
                <label className='mb-2'><b>Name</b></label>
                <div className="form-input">
                    <input className='form-control font-change' type="text" placeholder='Enter Name' id="fileInput" />
                </div>
            </div>
             <div className='col-sm-6 mb-2 font-change1'>
                <label className='mb-2'><b>Eamil</b></label>
                <div className="form-input">
                    <input className='form-control font-change' type="text" placeholder='Enter Eamil' id="fileInput" />
                </div>
            </div>
             <div className='col-sm-6 mb-2 font-change1'>
                <label className='mb-2'><b>Password</b></label>
                <div className="form-input">
                    <input className='form-control font-change' type="text" placeholder='Enter Password' id="fileInput" />
                </div>
            </div>
             <div className='col-sm-6 mb-2 font-change1'>
                <label className='mb-2'><b>Export Limit</b></label>
                <div className="form-input">
                    <input className='form-control font-change' type="text" placeholder='0' id="fileInput" />
                </div>
            </div>
        </div>
       
        </div>
      
      <div className="form-buttons">
        <div className='import-btn'>Add User</div>
        
      </div>
    </form>
  );
};

export default UserForm;
