import './login.css';
import { useState } from 'react';
import { Link } from 'react-router-dom';

function Register() {

    
  return (
    <div class="container">
        <div className="login-form">
            <div className='login-header'>Welcome to</div>
                <h3 className='login-name'>REVENUEWAVE</h3>

            
        <form >
            <div className="input-group1">
                <span className="input-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 26 26">
                <path fill="#5800ac" d="M16.563 15.9c-.159-.052-1.164-.505-.536-2.414h-.009c1.637-1.686 2.888-4.399 2.888-7.07c0-4.107-2.731-6.26-5.905-6.26c-3.176 0-5.892 2.152-5.892 6.26c0 2.682 1.244 5.406 2.891 7.088c.642 1.684-.506 2.309-.746 2.397c-3.324 1.202-7.224 3.393-7.224 5.556v.811c0 2.947 5.714 3.617 11.002 3.617c5.296 0 10.938-.67 10.938-3.617v-.811c0-2.228-3.919-4.402-7.407-5.557"/></svg></span>
               
                <input type="text" placeholder="Name" required=""/>
            </div>
            <div className="input-group1">
                <span className="input-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                <path fill="#5800ac" d="M21 16.42v3.536a1 1 0 0 1-.93.998Q19.415 21 19 21C10.163 21 3 13.837 3 5q0-.414.046-1.07A1 1 0 0 1 4.044 3H7.58a.5.5 0 0 1 .498.45q.034.344.064.552A13.9 13.9 0 0 0 9.35 8.003c.095.2.033.439-.147.567l-2.158 1.542a13.05 13.05 0 0 0 6.844 6.844l1.54-2.154a.46.46 0 0 1 .573-.149a13.9 13.9 0 0 0 4 1.205q.208.03.55.064a.5.5 0 0 1 .449.498"/></svg>
                </span>
                <input type="number" placeholder="Mobile/Phone Number" required=""/>
            </div>
            <div className="input-group1">
                <span className="input-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24"><g fill="#5800ac">
                    <path d="M2.038 5.61A2 2 0 0 0 2 6v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6q0-.18-.03-.352l-.866.65l-7.89 6.032a2 2 0 0 1-2.429 0L2.884 6.288l-.846-.677Z"/>
                    <path d="M20.677 4.117A2 2 0 0 0 20 4H4q-.338.002-.642.105l.758.607L12 10.742L19.9 4.7z"/></g></svg>
                </span>
               
                <input type="email" placeholder="Email" required=""/>
            </div>
            <div className="input-group1">
                <span className="input-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                    <path fill="#5800ac" fill-rule="evenodd" d="M22 8.293c0 3.476-2.83 6.294-6.32 6.294c-.636 0-2.086-.146-2.791-.732l-.882.878c-.519.517-.379.669-.148.919c.096.105.208.226.295.399c0 0 .735 1.024 0 2.049c-.441.585-1.676 1.404-3.086 0l-.294.292s.881 1.025.147 2.05c-.441.585-1.617 1.17-2.646.146l-1.028 1.024c-.706.703-1.568.293-1.91 0l-.883-.878c-.823-.82-.343-1.708 0-2.05l7.642-7.61s-.735-1.17-.735-2.78c0-3.476 2.83-6.294 6.32-6.294S22 4.818 22 8.293m-6.319 2.196a2.2 2.2 0 0 0 2.204-2.195a2.2 2.2 0 0 0-2.204-2.196a2.2 2.2 0 0 0-2.204 2.196a2.2 2.2 0 0 0 2.204 2.195" clip-rule="evenodd"/></svg>
                </span>
               
                <input type="password" placeholder="Password" required="" />
                <span className="input-toggle">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 15 15">
                    <path fill="#5800ac" fill-rule="evenodd" d="M7.5 7.686V9a1.5 1.5 0 0 1 0-3zM7.5 5a2.5 2.5 0 0 0 0 5v3c-2.686 0-4.561-1.348-5.747-2.665a10.368 10.368 0 0 1-1.61-2.407a6.05 6.05 0 0 1-.099-.222l-.006-.014l-.001-.004l-.001-.002L.5 7.5l-.464.186a.5.5 0 0 1 0-.372l.066.027a1.304 1.304 0 0 1-.066-.028v-.001l.002-.004l.006-.014a3.62 3.62 0 0 1 .1-.222a10.367 10.367 0 0 1 1.61-2.406C2.938 3.348 4.813 2 7.5 2zm0 1v3a1.5 1.5 0 1 0 0-3m0 4a2.5 2.5 0 0 0 0-5V2c2.686 0 4.561 1.348 5.747 2.666a10.365 10.365 0 0 1 1.61 2.406a6.164 6.164 0 0 1 .099.222l.005.014l.002.004l.001.002l-.364.146l.364-.146a.5.5 0 0 1 0 .372L14.5 7.5l.464.187v.001l-.003.004l-.005.014a3.334 3.334 0 0 1-.1.222a10.366 10.366 0 0 1-1.61 2.406C12.062 11.653 10.187 13 7.5 13z" clip-rule="evenodd"/></svg>
                </span>
            </div>
            <div className="input-group1">
                <span className="input-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                <path fill="#5800ac" d="M12 17a2 2 0 0 0 2-2a2 2 0 0 0-2-2a2 2 0 0 0-2 2a2 2 0 0 0 2 2m6-9a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V10a2 2 0 0 1 2-2h1V6a5 5 0 0 1 5-5a5 5 0 0 1 5 5v2zm-6-5a3 3 0 0 0-3 3v2h6V6a3 3 0 0 0-3-3"/></svg>
                 </span>
               
                <input type="password" placeholder="Confirm Password" required="" />
                <span className="input-toggle">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 15 15">
                    <path fill="#5800ac" fill-rule="evenodd" d="M7.5 7.686V9a1.5 1.5 0 0 1 0-3zM7.5 5a2.5 2.5 0 0 0 0 5v3c-2.686 0-4.561-1.348-5.747-2.665a10.368 10.368 0 0 1-1.61-2.407a6.05 6.05 0 0 1-.099-.222l-.006-.014l-.001-.004l-.001-.002L.5 7.5l-.464.186a.5.5 0 0 1 0-.372l.066.027a1.304 1.304 0 0 1-.066-.028v-.001l.002-.004l.006-.014a3.62 3.62 0 0 1 .1-.222a10.367 10.367 0 0 1 1.61-2.406C2.938 3.348 4.813 2 7.5 2zm0 1v3a1.5 1.5 0 1 0 0-3m0 4a2.5 2.5 0 0 0 0-5V2c2.686 0 4.561 1.348 5.747 2.666a10.365 10.365 0 0 1 1.61 2.406a6.164 6.164 0 0 1 .099.222l.005.014l.002.004l.001.002l-.364.146l.364-.146a.5.5 0 0 1 0 .372L14.5 7.5l.464.187v.001l-.003.004l-.005.014a3.334 3.334 0 0 1-.1.222a10.366 10.366 0 0 1-1.61 2.406C12.062 11.653 10.187 13 7.5 13z" clip-rule="evenodd"/></svg>
                </span>
            </div>
            <button type="submit" className="login-button">
                Register
            </button>
            <div className="register-link">
                Already have an account? <Link to="/login">Login</Link>
            </div>
    </form>


        </div>
    </div>
  );
}

export default Register;
