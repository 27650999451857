
import feather from "feather-icons";
import Sidebar from "./Sidebar";

import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import Login from "./login";
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import profile from './assets/profile.JPG'

import { Doughnut } from "react-chartjs-2";

import { Link } from "react-router-dom";


import "./App.css";
import "./leads.css";
import "./PricingPlans.css";


import { useEffect } from "react";
import Modal from "./modal";
import ImportForm from "./ImportForm";
import LeadForm from "./LeadForm";




function Subscription() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('Leads'); // State to track active tab
  const [activeBtn, setactiveBtn] = useState(null); // State to track active tab
  // Function to handle click on a list item
  const [isAnnual, setIsAnnual] = useState(true); // State to manage annual/monthly toggle

   

  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModallead, setShowModallead] = useState(false);
  
  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleOpenModallead = () => setShowModallead(true);
  const handleCloseModallead = () => setShowModallead(false);

  const handleFilterSidebar = () => {
      setIsFilterSidebarOpen(!isFilterSidebarOpen);
  };
  const closeSidebar = () => {
    setIsFilterSidebarOpen(false);
  }

  const handleToggle = () => {
    setIsAnnual(!isAnnual);
};

  return (
    <div className="App" style={{overflow:'hidden'}}>
       <Sidebar />
     
    
       <div className='home-body'>
      <span >
      Dashboard > <b>Subscription</b>
      
      </span>
        <section className="pricing-page">
            <span className="pricing-tagline">Pricing Plan</span>
            <h1 className="pricing-head">Plans for all sizes</h1>
            <div className="pricing-subhead">Simple,transparent pricing that grows with you. Try any plan free for 30 Days</div>
            <div className="pricing-toggle">
            <label className="switch">
                    <input type="checkbox" checked={isAnnual} onChange={handleToggle} />
                    <span className="slider round"></span>
                </label>
                <span>Annual pricing (save 20%)</span>
               
            </div>
            <div className="row">
              <div className="col-sm-4 pricing-plan-card">
                <div className="icon-back">
                  <div className="icon-back-extra">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                      <path fill="#5800ac" d="M11 9.47V11h3.76L13 14.53V13H9.24zM13 1L6 15h5v8l7-14h-5z" />
                    </svg>
                  </div>
                </div>
                <h3 className="plan-title">Basic Plan</h3>
                <h1 className="plan-rate">$10/mth</h1>
                <span className="text-muted plan-sub-head">Billed annualy.</span>
                
                <div className="plan-body row">
                  <div className="plan-body-icon col-3 text-right">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16">
                      <g fill="none" stroke="#5800ac" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
                        <path d="M14.25 8.75c-.5 2.5-2.385 4.854-5.03 5.38A6.25 6.25 0 0 1 3.373 3.798C5.187 1.8 8.25 1.25 10.75 2.25" />
                        <path d="m5.75 7.75l2.5 2.5l6-6.5" />
                      </g>
                    </svg>
                  </div>
                  <div className="col-9 text-left">Basic Filters</div>
                </div>
                <div className="plan-body row">
                  <div className="plan-body-icon col-3 text-right">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16">
                      <g fill="none" stroke="#5800ac" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
                        <path d="M14.25 8.75c-.5 2.5-2.385 4.854-5.03 5.38A6.25 6.25 0 0 1 3.373 3.798C5.187 1.8 8.25 1.25 10.75 2.25" />
                        <path d="m5.75 7.75l2.5 2.5l6-6.5" />
                      </g>
                    </svg>
                  </div>
                  <div className="col-9 text-left">Limited API Access</div>
                </div>
                <div className="plan-body row">
                  <div className="plan-body-icon col-3 text-right">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16">
                      <g fill="none" stroke="#5800ac" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
                        <path d="M14.25 8.75c-.5 2.5-2.385 4.854-5.03 5.38A6.25 6.25 0 0 1 3.373 3.798C5.187 1.8 8.25 1.25 10.75 2.25" />
                        <path d="m5.75 7.75l2.5 2.5l6-6.5" />
                      </g>
                    </svg>
                  </div>
                  <div className="col-9 text-left">Custom Stages</div>
                </div>
                <div className="plan-body row">
                  <div className="plan-body-icon col-3 text-right">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16">
                      <g fill="none" stroke="#5800ac" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
                        <path d="M14.25 8.75c-.5 2.5-2.385 4.854-5.03 5.38A6.25 6.25 0 0 1 3.373 3.798C5.187 1.8 8.25 1.25 10.75 2.25" />
                        <path d="m5.75 7.75l2.5 2.5l6-6.5" />
                      </g>
                    </svg>
                  </div>
                  <div className="col-9 text-left">Basic chat and email support</div>
                </div>
                <Link to='/checkout'><div className="plan-btn">Get Started</div></Link>
              </div>
              <div className="col-sm-4 pricing-plan-card">
                <div className="icon-back">
                  <div className="icon-back-extra">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                      <path fill="#5800ac" d="M11 9.47V11h3.76L13 14.53V13H9.24zM13 1L6 15h5v8l7-14h-5z" />
                    </svg>
                  </div>
                </div>
                <h3 className="plan-title">Basic Plan</h3>
                <h1 className="plan-rate">$10/mth</h1>
                <span className="text-muted plan-sub-head">Billed annualy.</span>
                
                <div className="plan-body row">
                  <div className="plan-body-icon col-3 text-right">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16">
                      <g fill="none" stroke="#5800ac" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
                        <path d="M14.25 8.75c-.5 2.5-2.385 4.854-5.03 5.38A6.25 6.25 0 0 1 3.373 3.798C5.187 1.8 8.25 1.25 10.75 2.25" />
                        <path d="m5.75 7.75l2.5 2.5l6-6.5" />
                      </g>
                    </svg>
                  </div>
                  <div className="col-9 text-left">Basic Filters</div>
                </div>
                <div className="plan-body row">
                  <div className="plan-body-icon col-3 text-right">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16">
                      <g fill="none" stroke="#5800ac" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
                        <path d="M14.25 8.75c-.5 2.5-2.385 4.854-5.03 5.38A6.25 6.25 0 0 1 3.373 3.798C5.187 1.8 8.25 1.25 10.75 2.25" />
                        <path d="m5.75 7.75l2.5 2.5l6-6.5" />
                      </g>
                    </svg>
                  </div>
                  <div className="col-9 text-left">Limited API Access</div>
                </div>
                <div className="plan-body row">
                  <div className="plan-body-icon col-3 text-right">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16">
                      <g fill="none" stroke="#5800ac" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
                        <path d="M14.25 8.75c-.5 2.5-2.385 4.854-5.03 5.38A6.25 6.25 0 0 1 3.373 3.798C5.187 1.8 8.25 1.25 10.75 2.25" />
                        <path d="m5.75 7.75l2.5 2.5l6-6.5" />
                      </g>
                    </svg>
                  </div>
                  <div className="col-9 text-left">Custom Stages</div>
                </div>
                <div className="plan-body row">
                  <div className="plan-body-icon col-3 text-right">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16">
                      <g fill="none" stroke="#5800ac" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
                        <path d="M14.25 8.75c-.5 2.5-2.385 4.854-5.03 5.38A6.25 6.25 0 0 1 3.373 3.798C5.187 1.8 8.25 1.25 10.75 2.25" />
                        <path d="m5.75 7.75l2.5 2.5l6-6.5" />
                      </g>
                    </svg>
                  </div>
                  <div className="col-9 text-left">Basic chat and email support</div>
                </div>
                <Link to='/checkout'><div className="plan-btn">Get Started</div></Link>
              </div>
              <div className="col-sm-4 pricing-plan-card">
                <div className="icon-back">
                  <div className="icon-back-extra">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                      <path fill="#5800ac" d="M11 9.47V11h3.76L13 14.53V13H9.24zM13 1L6 15h5v8l7-14h-5z" />
                    </svg>
                  </div>
                </div>
                <h3 className="plan-title">Basic Plan</h3>
                <h1 className="plan-rate">$10/mth</h1>
                <span className="text-muted plan-sub-head">Billed annualy.</span>
                
                <div className="plan-body row">
                  <div className="plan-body-icon col-3 text-right">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16">
                      <g fill="none" stroke="#5800ac" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
                        <path d="M14.25 8.75c-.5 2.5-2.385 4.854-5.03 5.38A6.25 6.25 0 0 1 3.373 3.798C5.187 1.8 8.25 1.25 10.75 2.25" />
                        <path d="m5.75 7.75l2.5 2.5l6-6.5" />
                      </g>
                    </svg>
                  </div>
                  <div className="col-9 text-left">Basic Filters</div>
                </div>
                <div className="plan-body row">
                  <div className="plan-body-icon col-3 text-right">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16">
                      <g fill="none" stroke="#5800ac" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
                        <path d="M14.25 8.75c-.5 2.5-2.385 4.854-5.03 5.38A6.25 6.25 0 0 1 3.373 3.798C5.187 1.8 8.25 1.25 10.75 2.25" />
                        <path d="m5.75 7.75l2.5 2.5l6-6.5" />
                      </g>
                    </svg>
                  </div>
                  <div className="col-9 text-left">Limited API Access</div>
                </div>
                <div className="plan-body row">
                  <div className="plan-body-icon col-3 text-right">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16">
                      <g fill="none" stroke="#5800ac" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
                        <path d="M14.25 8.75c-.5 2.5-2.385 4.854-5.03 5.38A6.25 6.25 0 0 1 3.373 3.798C5.187 1.8 8.25 1.25 10.75 2.25" />
                        <path d="m5.75 7.75l2.5 2.5l6-6.5" />
                      </g>
                    </svg>
                  </div>
                  <div className="col-9 text-left">Custom Stages</div>
                </div>
                <div className="plan-body row">
                  <div className="plan-body-icon col-3 text-right">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16">
                      <g fill="none" stroke="#5800ac" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
                        <path d="M14.25 8.75c-.5 2.5-2.385 4.854-5.03 5.38A6.25 6.25 0 0 1 3.373 3.798C5.187 1.8 8.25 1.25 10.75 2.25" />
                        <path d="m5.75 7.75l2.5 2.5l6-6.5" />
                      </g>
                    </svg>
                  </div>
                  <div className="col-9 text-left">Basic chat and email support</div>
                </div>
                <Link to='/checkout'><div className="plan-btn">Get Started</div></Link>
              </div>
            </div>

        </section>
    </div>
 
     
      
    </div>
  );
}

export default Subscription;

 